import moment, { Moment } from 'moment-timezone';
import lodash, { clone } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  IProviderDaySlot,
  ISlots,
  InPersonClinic,
} from '../../shared/types/response/provider';
import { displayJaDateFormat, formatNumberTo2Digits } from './Display';
import { ISlotTime } from '../../shared/types/Time';
import { dayOfWeekNumbers } from '../../shared/constant/Common';
import { getItemFromLocalStorage } from './Storage';

export const APIDateFormat = 'YYYY/MM/DD';
export const BillingMonthFormat = 'MMMM YYYY';
export const NewBillingMonthFormat = 'YYYY/MM';
export const APIDisplayTimeFormat = 'hh:mm A';
export const RecentChatMessageFormat = 'HH:mm A';
export const OldChatMessageFormat = 'dddd, MMM Do hh:mm A';
export const OldChatContactFormat = 'DD/MM/YYYY';
export const OnsiteWarningTimeFormat = 'hh:mm A, DD MMM';
export const getNumberOFDaysInAMonth = (month: string): number =>
  moment(month, 'YYYY-MM').daysInMonth();

export const getTodaysDate = (format: string): string =>
  moment().format(format);
export const getTimeZone = () =>
  (getItemFromLocalStorage('userTimeZone', 'string') as string) ||
  moment.tz.guess(true);

export const slotToTime = (slot: number) =>
  `${formatNumberTo2Digits(
    +Math.floor((+slot * 30) / 60).toFixed(0),
  )}:${formatNumberTo2Digits((+slot * 30) % 60)}`;

const convertDayMapToSlotRange = (
  input: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
) => {
  const weekDays = clone(input) as Record<string, IProviderDaySlot>;

  Object.keys(weekDays).forEach((key: string) => {
    weekDays[key].slotsRange = [];
    Object.entries(weekDays[key].slots).forEach(
      ([locationIdKey, slotsForLocationId]) => {
        if (slotsForLocationId.length) {
          weekDays[key].slots[locationIdKey] = slotsForLocationId.sort(
            (a: number, b: number) => a - b,
          );

          let current = slotsForLocationId[0];
          let last = slotsForLocationId[0];

          slotsForLocationId.forEach((cur: number, index: number) => {
            if (index === 0) {
              // skip
            } else if (last === cur - 1) {
              last = cur;
            } else {
              weekDays[key].slotsRange.push({
                locationId: locationIdKey === 'virtual' ? null : locationIdKey,
                slots: [current, last],
              });

              current = cur;
              last = cur;
            }
          });

          weekDays[key].slotsRange.push({
            locationId: locationIdKey === 'virtual' ? null : locationIdKey,
            slots: [current, last],
          });
        }
      },
    );
  });

  return weekDays;
};

export const getSlotsByWeekDay = (slotDays: ISlots[]) => {
  const timeZone = getTimeZone();
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce(
    (
      res: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
      cur: ISlots,
    ) => {
      if (cur.slots) {
        cur.slots.split(',').forEach((slot: string) => {
          const time = moment(
            `${cur.dayOfWeek} ${slotToTime(+slot)}`,
            'd HH:mm',
          ).add(offset, 'minute');

          if (!res[time.format('d')]) {
            res[time.format('d')] = {
              day: time.format('d'),
              dayDisplay: time.format('dddd'),
              slots: { [cur.locationId || 'virtual']: [], virtual: [] },
            };
          }

          if (!res[time.format('d')].slots[cur.locationId || 'virtual']) {
            res[time.format('d')].slots[cur.locationId || 'virtual'] = [];
          }

          res[time.format('d')].slots[cur.locationId || 'virtual'].push(
            +time.format('HH') * 2 +
              +Math.floor(+time.format('mm') / 30).toFixed(0),
          );
        });
      }
      return res;
    },
    {},
  );

  return convertDayMapToSlotRange(weekDays);
};

export const getSlotsByWeekDayForDailyOverrides = (slotDays: ISlots[]) => {
  const timeZone = getTimeZone();
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce(
    (
      res: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
      cur: ISlots,
    ) => {
      if (cur.slots) {
        cur.slots.split(',').forEach((slot: string) => {
          const time = moment(
            `${cur.dayOfWeek} ${slotToTime(+slot)}`,
            'd HH:mm',
          ).add(offset, 'minute');
          if (!res[cur.dayOfWeek]) {
            res[cur.dayOfWeek] = {
              day: time.format('d'),
              dayDisplay: time.format('dddd'),
              slots: { [cur.locationId || 'virtual']: [], virtual: [] },
            };
          }

          if (!res[cur.dayOfWeek].slots[cur.locationId || 'virtual']) {
            res[cur.dayOfWeek].slots[cur.locationId || 'virtual'] = [];
          }

          res[cur.dayOfWeek].slots[cur.locationId || 'virtual'].push(
            +time.format('HH') * 2 +
              +Math.floor(+time.format('mm') / 30).toFixed(0),
          );
        });
      }
      return res;
    },
    {},
  );

  Object.keys(weekDays).forEach((day) => {
    Object.keys(weekDays[day].slots).forEach((key) => {
      weekDays[day].slots[key] = lodash.uniq(weekDays[day].slots[key]).sort();
    });
  });

  return convertDayMapToSlotRange(weekDays);
};

export const getSlotTz = (date: string, slot: number) => {
  const timeZone = getTimeZone();
  const slotDateTime = moment.utc(
    `${date} ${slotToTime(+slot)}`,
    'YYYY/MM/DD HH:mm',
  );
  return {
    displayDate: slotDateTime.tz(timeZone).format('YYYY/MM/DD'),
    displayTime: slotDateTime.tz(timeZone).format('HH:mm'),
  };
};

export const getAvailableBillingMonthRange = () => {
  const viewableMonthsCount = 6;
  const monthsRange = [];
  for (let i = viewableMonthsCount - 1; i >= 0; i -= 1) {
    const month = moment().subtract(i, 'months').format(BillingMonthFormat);
    monthsRange.push(month);
  }

  return monthsRange;
};

export const transformSlotsToWeekDay = (
  slotMap: Record<string, IProviderDaySlot>,
  clinicsList: InPersonClinic[],
  toUtcSlots: boolean = true,
) => {
  const offset = moment.tz(getTimeZone()).utcOffset();

  const momentArray: { [locationId: string]: Moment[] } = { virtual: [] };

  Object.values(slotMap).forEach((providerSlots) => {
    providerSlots.slotsRange.forEach((slotRange) => {
      if (!momentArray[slotRange.locationId || 'virtual']) {
        momentArray[slotRange.locationId || 'virtual'] = [];
      }

      const mergedSlots = lodash.range(
        slotRange.slots[0],
        +slotRange.slots[1] + 1,
      );

      mergedSlots.forEach((mergedSlot) => {
        if (toUtcSlots) {
          momentArray[slotRange.locationId || 'virtual'].push(
            moment(
              `${providerSlots.day} ${slotToTime(+mergedSlot)}`,
              'd HH:mm',
            ).subtract(offset, 'minutes'),
          );
        } else {
          momentArray[slotRange.locationId || 'virtual'].push(
            moment(
              `${providerSlots.day} ${slotToTime(+mergedSlot)}`,
              'd HH:mm',
            ),
          );
        }
      });
    });
  });

  const dMap: Record<string, { [locationId: string]: Set<number> }> =
    Object.fromEntries(
      dayOfWeekNumbers.map((day) => [
        day,
        {
          ...Object.fromEntries(
            clinicsList.map((clinic) => [clinic.id, new Set<number>()]),
          ),
          virtual: new Set<number>(),
        },
      ]),
    );

  Object.entries(momentArray).forEach(([locId, times]) => {
    times.forEach((time) => {
      if (!dMap[+time.format('d')][locId]) {
        dMap[+time.format('d')][locId] = new Set();
      }

      dMap[+time.format('d')][locId].add(
        +time.format('HH') * 2 +
          +Math.floor(+time.format('mm') / 30).toFixed(0),
      );
    });
  });

  const body: ISlots[] = Object.entries(dMap).flatMap(([day, slotsOfDay]) =>
    Object.entries(slotsOfDay).map(([locId, slotsAtLoc]) => {
      const slots: number[] = [];
      slotsAtLoc.forEach((slot) => slots.push(slot));
      return {
        dayOfWeek: +day,
        locationId: locId === 'virtual' ? null : locId,
        slots: slots.sort((a: number, b: number) => a - b).join(','),
      };
    }),
  );

  return body;
};

export const convertTo12HourFormat = (
  time: string,
  format: string = 'h:mm A',
) => moment(time, 'HH:mm').format(format);

export const generate30MinTimeIntervals = ({
  offset = 0,
  isEnd,
  is12HrFormat = true,
  totalSlot = 48,
}: {
  offset?: number;
  isEnd?: boolean;
  is12HrFormat?: boolean;
  totalSlot?: number;
}): ISlotTime[] => {
  // TODO make it generic
  const format = is12HrFormat ? 'hh:mm A' : 'HH:mm';
  const timeIntervals: ISlotTime[] = lodash
    .range(offset, totalSlot)
    .map((slot) => ({
      slot,
      time: convertTo12HourFormat(slotToTime(+slot + (isEnd ? 1 : 0)), format),
    }));

  // for (let hour = 0; hour < 24; hour += 1) {
  //   timeIntervals.push(moment({ hour }).format('h:mm A'));
  //   timeIntervals.push(
  //     moment({
  //       hour,
  //       minute: 30,
  //     }).format('h:mm A'),
  //   );
  // }

  return timeIntervals;
};

export const getTimeRangeFromSlotRange = (slotRange: number[]) => {
  const [startSlot, endSlot] = slotRange;
  const startTime = convertTo12HourFormat(slotToTime(startSlot));
  const endTime = convertTo12HourFormat(slotToTime(endSlot + 1));

  return { startTime, endTime };
};

export const isEnterRoomAllowedTime = (startTime: string, endTime: string) => {
  const currentTime = moment();
  const earlyJoiningMinutesLimit = 5;
  const lateJoiningMinutesLimit = 2;
  const startTimeMoment = moment(startTime, APIDisplayTimeFormat).subtract(
    earlyJoiningMinutesLimit,
    'minutes',
  );
  const endTimeMoment = moment(endTime, APIDisplayTimeFormat).add(
    lateJoiningMinutesLimit,
    'minutes',
  );

  return currentTime.isBetween(startTimeMoment, endTimeMoment);
};

export const getSlotsByDate = (slotDays: any[]) => {
  const timeZone = getTimeZone();
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce((res: any, cur: any) => {
    cur.slots.split(',').forEach((slot: string) => {
      const time = moment(
        `${cur.date} ${slotToTime(+slot)}`,
        'YYYY/MM/DD HH:mm',
      ).add(offset, 'minute');
      if (!res[time.format('YYYY/MM/DD')]) {
        res[time.format('YYYY/MM/DD')] = {
          day: time.format('YYYY/MM/DD'),
          dayDisplay: time.format('YYYY/MM/DD'),
          slots: new Set(),
        };
      }
      res[time.format('YYYY/MM/DD')].slots.add(
        +time.format('HH') * 2 +
          +Math.floor(+time.format('mm') / 30).toFixed(0),
      );
    });
    return res;
  }, {});
  return convertDayMapToSlotRange(weekDays);
};

export const getVoiceNoteElapsedTime = (elapsedSeconds: number) =>
  moment.utc(elapsedSeconds * 1000).format('mm:ss');

export const getWeekDay = (utcDate: string) => moment(utcDate).day();

export const getTimeFromMilliSeconds = (milliSeconds: number, format: string) =>
  moment.unix(milliSeconds).format(format);

export interface IDisplayDate {
  locale: string;
  selectedDate: Moment | string;
  format: { [key: string]: string };
  translatorResource?: {
    ja?: string;
    en?: string;
    common?: string;
  };
  splitWith?: string;
}
export const getDisplayDate = ({
  locale,
  selectedDate,
  format,
  translatorResource = { ja: 'DISPLAY_DATE_WITH_MENTION' },
  splitWith = ',',
}: IDisplayDate): string => {
  const { t } = useTranslation();
  const formattedDate = moment(selectedDate).format(
    format[locale] || format.default || displayJaDateFormat,
  );
  const [month, date, year, day] = formattedDate.split(splitWith);
  if (translatorResource) {
    if (
      locale.includes('ja') &&
      (translatorResource?.ja || translatorResource?.common)
    ) {
      const ja = translatorResource?.ja || translatorResource?.common;
      return t(ja as string, { year, month, date, day });
    }
    if (
      locale.includes('en') &&
      (translatorResource?.en || translatorResource?.common)
    ) {
      const en = translatorResource?.en || translatorResource?.common;
      return t(en as string, { year, month, date, day });
    }
  }
  return formattedDate;
};

export const getDateRange = (firstDate: string, lastDate: string) => {
  if (
    moment(firstDate, APIDateFormat).isSame(
      moment(lastDate, APIDateFormat),
      'day',
    )
  )
    return [lastDate];
  let date = firstDate;
  const dates = [date];
  do {
    date = moment(date).add(1, 'day').format(APIDateFormat);
    dates.push(date);
  } while (moment(date).isBefore(lastDate));
  return dates;
};

export const isTimeZoneSame = (
  backendTimezone: string,
  deviceTimezone: string,
): boolean => {
  const backendOffset = moment.tz(backendTimezone).utcOffset();
  const deviceOffset = moment.tz(deviceTimezone).utcOffset();
  return backendOffset === deviceOffset;
};

export const getTimezoneInfo = ({ timezone }: { timezone: string }) => {
  const formattedInfoLong = `${new Date()
    .toLocaleString(undefined, {
      day: '2-digit',
      timeZoneName: 'long',
    })
    .substring(4)} (GMT ${moment.tz(timezone).format('Z')})`;

  return formattedInfoLong;
};
