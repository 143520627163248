import { RawAxiosRequestHeaders } from 'axios';
import moment from 'moment';
import Config from '../../../shared/Config';
import NetworkClient from '../../../shared/service/Network';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from '../../../utilities/common/Storage';
import { StorageItems } from '../../../shared/constant/App';
import { beautifyUrl } from '../../../utilities/common/Path';
import { SendMessageTypes } from '../../data-types/ClientTypes';

export const errorMessage = {
  generic: 'Unable to load the data, please try again!',
};

export function isIrisTokenExpired(expToken: number) {
  const current = moment(Date.now());
  const exp = moment(expToken);
  if (exp.diff(current, 'minutes') < 4) {
    return true;
  }

  return false;
}

export const getIrisAccessToken = async (
  client: NetworkClient,
  accessToken: string,
) => {
  const response = await client.doGet(Config.api.iris.getIrisAccessToken, {
    accessToken,
  });
  if (response && response.data) {
    addItemToLocalStorage('irisExpiryTime', response.data.exp);
    addItemToLocalStorage('irisAccessToken', response.data.token);
    return response.data;
  }

  throw new Error('Error');
};

export function getIrisApiHeader() {
  const headers: RawAxiosRequestHeaders = {
    'Content-Type': undefined,
    Authorization: getItemFromLocalStorage(
      'irisAccessToken',
      'string',
    ).toString(),
  };
  return headers;
}

export const getIrisProviderConversationList = async (
  client: NetworkClient,
) => {
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  if (isIrisTokenExpired(Number(expToken))) {
    const { token }: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    await getIrisAccessToken(client, token);
  }
  const response = await client.doGet(
    Config.api.iris.getIrisProviderConversations,
    getIrisApiHeader(),
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};

export const sendIrisMessage = async (
  client: NetworkClient,
  { data, conversationId }: SendMessageTypes,
) => {
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  if (isIrisTokenExpired(Number(expToken))) {
    const { token }: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    await getIrisAccessToken(client, token);
  }
  const response = await client.doPost(
    beautifyUrl(Config.api.iris.sendIrisMessage, [conversationId]),
    data,
    getIrisApiHeader(),
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};

export const getIrisMessages = async (
  client: NetworkClient,
  conversationId: string,
  page: string,
) => {
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  if (isIrisTokenExpired(Number(expToken))) {
    const { token }: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    await getIrisAccessToken(client, token);
  }
  const response = await client.doGet(
    beautifyUrl(Config.api.iris.getIrisMessages, [conversationId, page]),
    getIrisApiHeader(),
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};
