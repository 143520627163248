import * as React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Popup from '../../../../components/Popup';
import Button from '../../../../components/Button';
import successIcon from '../../../../../assets/issues_success.png';

export interface CustomIssueModalProps {
  onClose: () => void;
  show: boolean;
  onSubmit: ({ topic, description }: any) => void;
  modalState: 'INPUT' | 'SUCCESS';
}

function CustomIssueModal({
  onClose,
  show,
  onSubmit,
  modalState,
}: CustomIssueModalProps) {

  const { t } = useTranslation();
  const [topic, setTopic] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');

  const renderInputState = () => (
    <div className='flex flex-col flex-wrap justify-between gap-8'>
      <div className='flex flex-col'>
        <span className='text-xl font-medium text-[#101828]'>{t('ADD_PI_TITLE')}</span>
        <span className='text-base font-normal text-[#667085]'>{t('ADD_PI_SUBHEADER')}</span>
      </div>
      <div>
        <div>
          <motion.input
            type='text'
            className='w-full h-11 border border-[#DADCE8] rounded-lg p-3 mb-4'
            onChange={(e) => setTopic(e.target.value)}
            value={topic}
            placeholder={t('ADD_PI_PROMPT_1')}
          />
          <textarea
            className='w-full h-48 p-4 border border-[#DADCE8] rounded-lg text-base resize-none'
            placeholder={t('ADD_PI_PROMPT_2')}
            onChange={(e) =>
              setDescription(e.target.value)
            }
            value={description}
          />
        </div>
      </div>
      <Button
        label={t('SUBMIT_SUGGESTION')}
        variant='blue'
        disableAnimation
        disabled={!topic || !description}
        onClick={() => {
          onSubmit({ topic, description });
        }}
        height='42px'
        width='100%'
      />
    </div>
  );

  const renderSuccessState = () => (
    <div
      className='flex flex-col flex-wrap justify-center items-center w-[50vw] gap-2'
    >
      <img src={successIcon} alt='empty' width={200} height={40} />
      <span className='text-base font-semibold text-[#101828]'>{t('ADD_PI_SUCCESS_TITLE')}</span>
      <span className='text-base font-normal text-center text-[#475467] w-2/3 mb-5'>{t('ADD_PI_SUCCESS_BODY')}</span>
      <Button
        label={t('ADD_PI_SUCCESS_CTA')}
        variant='blue'
        onClick={() => onClose()}
        height='42px'
        width='400px'
      />
    </div>
  );

  return (
    <Popup withBackdrop show={show} useDefaultPadding={false} onClose={onClose} showCloseIcon={modalState === 'INPUT'}>
      <div className='p-6 flex justify-center items-center min-v-[50vw] min-h-80 '>
        {modalState === 'INPUT' ? renderInputState() : renderSuccessState()}
      </div>
    </Popup>
  );
}

export default CustomIssueModal;
