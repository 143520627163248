import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IFollowUpSessionsForm,
  IFollowUpSessionsFormSections,
} from '../../FollowUpSession.type';
import {
  IMultiOption,
  IMultiOptionsData,
} from '../../../../components/MultilevelDropdown/types';
import './PresentingIssues.scss';
import Heading from '../../Heading';
import Typography from '../../../../components/Typography';
import ComboBox from '../../../../components/ComboBox';
import CustomIssueModal from '../CustomIssueModal';
import { useRequestCustomIssue } from '../../../../../utilities/hooks/fetchHooks/provider';
import useToast from '../../../../../utilities/hooks/useToast';

interface IPresentingIssues {
  primaryHeaderContent?: React.ReactNode;
  secondaryHeaderContent?: React.ReactNode;
  primaryOptions?: IMultiOptionsData[] | IMultiOption[];
  secondaryOptions?: IMultiOptionsData[] | IMultiOption[];
  primaryQuestion?: { id?: number; question: string };
  secondaryQuestion?: { id?: number; question: string };
  hideTag?: boolean;
  meetingId: string;
}

function PresentingIssues({
  watch,
  setValue,
  secondaryHeaderContent,
  primaryHeaderContent,
  primaryOptions,
  secondaryOptions,
  primaryQuestion,
  secondaryQuestion,
  hideTag = false,
  meetingId,
}: IFollowUpSessionsFormSections & IPresentingIssues) {
  const { t } = useTranslation();
  const toast = useToast();
  const [showRequestModal, setShowRequestModal] = React.useState<boolean>(false);
  const [modalState, setModalState] = React.useState<'INPUT' | 'SUCCESS'>('INPUT');
  const [customIssue, setCustomIssue] = React.useState<{ topic: string; description: string; }>({
    topic: '',
    description: '',
  });

  const { mutate: postIssue } = useRequestCustomIssue(
    {
      meetingId,
      topic: customIssue.topic,
      description: customIssue.description,
    },
    {
      onSuccess: () => {
        setModalState('SUCCESS');
      },
      onError: (error: any) => {
        toast.show({ message: error.message });
      },
    },
  );

  React.useEffect(() => {
    if (customIssue?.topic?.length > 0 && customIssue?.description?.length > 0) {
      postIssue();
    }
  }, [customIssue]);

  const handleChange =
    (key: keyof IFollowUpSessionsForm) =>
      (value: IMultiOption | IMultiOption[]) => {
        if (setValue) setValue(key, value as IMultiOptionsData);
      };

  const resetData = () => {
    setModalState('INPUT');
    setCustomIssue({
      topic: '',
      description: '',
    });
    setShowRequestModal(false);
  };

  return (
    <div>
      {showRequestModal && (
        <CustomIssueModal
          show={showRequestModal}
          onClose={() => {
            resetData();
          }}
          modalState={modalState}
          onSubmit={(data: any) => {
            setCustomIssue(data);
          }}
        />
      )}
      <section className="primary-issues">
        {primaryHeaderContent || (
          <Heading
            headerText={primaryQuestion?.question}
            hideTooltipChips
            toolTipID="-1"
          />
        )}
        <div>
          <ComboBox
            onChange={handleChange('primaryIssue')}
            multiDropValues={primaryOptions as IMultiOptionsData[]}
            isMultiSelect={false}
            defaultSelected={watch('primaryIssue') as IMultiOptionsData[]}
            onEmptyCtaClick={() => setShowRequestModal(true)}
          />
        </div>
      </section>
      <section className="secondary-issues">
        {secondaryHeaderContent || (
          <Heading
          headerNode={
            <Typography size={14} weight="600">
              {secondaryQuestion?.question}
              <span className="subTextLimit">
                &nbsp;({t('CHOOSE_MAX_THREE')})
              </span>
            </Typography>
          }
          toolTipID="2"
          hideTooltipChips={hideTag}
        />
        )}
        <ComboBox
          onChange={handleChange('secondaryIssue')}
          multiDropValues={secondaryOptions as IMultiOptionsData[]}
          isMultiSelect
          defaultSelected={watch('secondaryIssue') as IMultiOptionsData[]}
          onEmptyCtaClick={() => setShowRequestModal(true)}
        />
      </section>
    </div>
  );
}

export default PresentingIssues;
