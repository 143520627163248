import React from 'react';
// import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Popup from '../../../components/Popup';
import ComboBox from '../../../components/ComboBox';
import { IMultiOptionsData } from '../../../components/ComboBox/types';
import { useRequestCustomIssue } from '../../../../utilities/hooks/fetchHooks/provider';
import CustomIssueModal from '../../FollowupSessions/components/CustomIssueModal';
import useToast from '../../../../utilities/hooks/useToast';

export interface CustomIssueModalProps {
  onClose: () => void;
  show: boolean;
  onSave: (val: any) => void;
  primaryOptions: IMultiOptionsData[] | any;
  meetingId: string;
}

function PrimaryIssueModal({
  onClose,
  show,
  onSave,
  primaryOptions,
  meetingId,
}: CustomIssueModalProps) {

  const { t } = useTranslation();
  const [selectedVal, setSelectedVal] = React.useState<any>('');
  const toast = useToast();

  const [showRequestModal, setShowRequestModal] = React.useState<boolean>(false);
  const [modalState, setModalState] = React.useState<'INPUT' | 'SUCCESS'>('INPUT');
  const [customIssue, setCustomIssue] = React.useState<{ topic: string; description: string; }>({
    topic: '',
    description: '',
  });

  const { mutate: postIssue } = useRequestCustomIssue(
    {
      meetingId,
      topic: customIssue.topic,
      description: customIssue.description,
    },
    {
      onSuccess: () => {
        setModalState('SUCCESS');
      },
      onError: (error: any) => {
        toast.show({ message: error.message });
      },
    },
  );

  React.useEffect(() => {
    if (customIssue?.topic?.length > 0 && customIssue?.description?.length > 0) {
      postIssue();
    }
  }, [customIssue]);

  const resetData = () => {
    setModalState('INPUT');
    setCustomIssue({
      topic: '',
      description: '',
    });
    setShowRequestModal(false);
  };

  return (
    <Popup withBackdrop show={show} useDefaultPadding={false} onClose={onClose}>
      {showRequestModal && (
        <CustomIssueModal
          show={showRequestModal}
          onClose={() => {
            resetData();
          }}
          modalState={modalState}
          onSubmit={(data: any) => {
            setCustomIssue(data);
          }}
        />
      )}
      <div className="p-6 flex justify-between items-center min-v-[50vw] h-96 min-h-80 z-20">
        <motion.div
          className='flex flex-col flex-wrap justify-center items-center w-[50vw] gap-2'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <span className='text-base font-semibold text-center text-[#101828]'>{t('HEADER_PI_POPUP')}</span>
          <span className='text-base font-normal text-center text-[#475467] w-2/3'>{t('NOTE_PI_POPUP')}</span>
          <div className='w-full z-20 mb-4 mt-4'>
            <span className="text-base font-semibold text-center text-[#475467]">
              {t('PRIMARY_PRESENTING_ISSUE')}
            </span>
            {primaryOptions && primaryOptions.length > 0 && (
              <ComboBox
                onChange={(val) => {
                  setSelectedVal(val);
                }}
                multiDropValues={primaryOptions as IMultiOptionsData[]}
                isMultiSelect={false}
                onEmptyCtaClick={() => setShowRequestModal(true)}
              />
            )}
          </div>
          <Button
            label={t('SAVE')}
            disabled={!selectedVal}
            variant="blue"
            onClick={() => {
              onSave(selectedVal);
              onClose();
            }}
            height="42px"
            width="400px"
          /> 
        </motion.div>
      </div>
    </Popup>
  );
}

export default PrimaryIssueModal;
