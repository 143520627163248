/* eslint-disable */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../../components/Popup';
import { ICancellationModal, bookSessionReqType } from './types';
import CancellationWarningModal from '../CancellationWarning';
import LeaveNote from '../LeaveNote';
import CancellationReasons from '../CancellationReasons/CancellationReasons';
import {
  CancellationModalContent,
  CancellingPendingStatus,
  ProviderCancellationReasons,
} from '../../../shared/constant/CancellationReasons';
import RescheduleSession from '../RescheduleSession';
import useToast from '../../../utilities/hooks/useToast';
import {
  useCancellationData,
  useLateCancellationPercent,
} from '../../../utilities/hooks/useCancellationData/useCancellationData';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import LateCancellationPercentage from '../LateCancellationPercentage/LateCancellationPercentage';
import Loader from '../../components/Loader';
import ChartWidget from '../../components/Charts/ChartWidget';
import Typography from '../../components/Typography';
import { StatusValues } from '../../../shared/constant/ProviderBooking';
import { VideoRoomSDKs } from '../../../shared/constant/App';
import InformationModal from '../InformationModal';
import { ProviderRole } from '../../../shared/constant/Common';

function CancellationReasonsModal({
  onClose,
  onSubmit,
  within24Hrs,
  sessionData,
  onWarningCancel,
  isReschedule,
  onCloseRescheduleModal,
}: ICancellationModal) {
  const getInitialModal = () => {
    if (sessionData.type === VideoRoomSDKs.f2f) {
      return CancellationModalContent.IN_PERSON_TYPE;
    }
    if (within24Hrs && sessionData.status !== StatusValues.isPending) {
      return CancellationModalContent.LATE_CANCELATION_METRIC;
    }
    if (sessionData.status === StatusValues.isPending)
      return CancellationModalContent.PENDING_INFO_CONTENT;

    return CancellationModalContent.WARNING_CONTENT;
  };
  const { t } = useTranslation();
  const { track } = useTracking();
  const toast = useToast();
  const cancellationModalInitialContent: string = getInitialModal();
  const [currentModalName, setCurrentModalName] = React.useState<string>(
    cancellationModalInitialContent,
  );
  const reasonCodeRef = React.useRef<string>('');
  const rescheduleMeetingData = React.useRef<bookSessionReqType>();
  const otherReasonRef = React.useRef<string>('');
  const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();
  const chartComponentParentRef = React.useCallback((node) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);
  const onContinue = (reasonCode: string) => {
    reasonCodeRef.current = reasonCode;
    track(EventNames.providerCancelSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.cancellationReason,
      eventLabel: 'continue_button',
      eventValue: t(
        `${Object.keys(ProviderCancellationReasons).find(
          (key) => ProviderCancellationReasons[key] === reasonCode,
        )}`,
      ),
      featureVersion: 'v1',
    });
    if (
      isReschedule &&
      reasonCode !==
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CLIENT_ASKED
    ) {
      setCurrentModalName(CancellationModalContent.RESCHEDULE_CONTENT);
      return;
    } else {
      setCurrentModalName(CancellationModalContent.LEAVE_NOTE_CONTENT);
    }
  };

  const handleOtherReasonChange = (value: string) =>
    (otherReasonRef.current = value);

  const onRescheduleSuccess = () => {
    const successMsg =
      reasonCodeRef.current ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION &&
      sessionData.scheduledStartTime ===
        rescheduleMeetingData.current?.scheduledStartTime
        ? t('SUCCESS_PROMPT_SESSION_EXTEND')
        : t('RESCHEDULE_SUCCESS_PROMPT', {
            name: sessionData.friendlyName,
          });
    toast.show({
      message: successMsg,
    });
    if (onCloseRescheduleModal) {
      onCloseRescheduleModal();
    }
  };
  const onRescheduleError = (err: Error) => {
    toast.show({
      message: err?.message,
    });
  };

  const { rescheduleSession } = useCancellationData({
    onSuccess: onRescheduleSuccess,
    onError: onRescheduleError,
  });

  const getLeaveNoteSubHeader = () => {
    if (
      reasonCodeRef.current ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CLIENT_ASKED &&
      within24Hrs
    ) {
      return t('CLIENT_ASKED_ME_TO_WARNING_2');
    }
    if (
      reasonCodeRef.current ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CLIENT_ASKED &&
      !within24Hrs
    ) {
      return t('CLIENT_ASKED_ME_TO_WARNING_1');
    }
    if (
      reasonCodeRef.current ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION &&
      sessionData.scheduledStartTime ===
        rescheduleMeetingData.current?.scheduledStartTime
    ) {
      return t('SESSION_EXTEND_SUBTEXT');
    }
    if (isReschedule) {
      return t('RESCHEDULE_SUBHEADER');
    }
    return t('CANCELLATION_SUBHEADER');
  };

  const getLatePercentageErrorToast = () => {
    toast.show({ message: t('UNABLE_TO_LOAD') });
  };
  const {
    data: lateCancellationPercentage,
    isLoading: isChartDataLoading,
    isSuccess: lateCancelledPercentageSuccess,
  } = useLateCancellationPercent({
    roleId: Number(sessionData.providerRole),
    onError: getLatePercentageErrorToast,
    withIn24hrs: Boolean(within24Hrs),
  });

  const handleReschedule = () => {
    onWarningCancel(true);
    setCurrentModalName(CancellationModalContent.CANCELLATION_REASONS);
  };

  const handleCancelAnyway = () => {
    onWarningCancel(false);
    setCurrentModalName(CancellationModalContent.CANCELLATION_REASONS);
  };

  const getLatePercentage = () => {
    if (
      lateCancelledPercentageSuccess &&
      lateCancellationPercentage?.lateCancellationPercentage
    ) {
      return Number(lateCancellationPercentage.lateCancellationPercentage);
    }
    return 0;
  };

  const getCurrentValueOfLP = () => {
    const currPercent: number = getLatePercentage();
    // since the indictor is not properly visible for less than 10
    if (currPercent > 0 && currPercent < 10) return 10;
    return currPercent;
  };
  const getLatePercentageChartColor = () => {
    const percentile: number = getLatePercentage();
    if (percentile >= 0 && percentile < 4) return 'rgba(60, 165, 116, 0.5)';
    if (percentile >= 4 && percentile < 8) return 'rgba(251, 222, 157, 1)';
    return 'rgba(242, 150, 150, 1)';
  };

  const getDisplayText = () => {
    return `${getLatePercentage()}%`;
  };

  // provided with static reason
  const handlePendingStatus = () => {
    onSubmit(
      CancellingPendingStatus.NOTE,
      false,
      ProviderCancellationReasons.CANCEL_REASON_PROVIDER_OTHERS,
      CancellingPendingStatus.OTHER_REASON,
    );
  };

  const handleRescheduleCancel = () => {
    onWarningCancel(false);
    setCurrentModalName(CancellationModalContent.CANCELLATION_REASONS);
  };
  const getLateCancellationSubHeader = () => {
    const moreThan10Percent = (
      <div>
        <section className="lcp-subheader-bold">{t('')}</section>
        {t('CANCELLATION_MORE_THAN_10_CONTENT')}
      </div>
    );
    const lessThan10Percent = (
      <div>{t('CANCELLATION_LESS_THAN_10_CONTENT')}</div>
    );
    return getLatePercentage() >= 10 ? moreThan10Percent : lessThan10Percent;
  };

  const currentModalContent = (modalName: string) => {
    switch (modalName) {
      case CancellationModalContent.WARNING_CONTENT:
        return (
          <CancellationWarningModal
            onCancel={handleRescheduleCancel}
            onConfirm={handleReschedule}
            header={
              within24Hrs
                ? t('CONFIRMATION_HEADER')
                : t('RESCHEDULE_SESSION_HEADER')
            }
            subHeader={
              within24Hrs
                ? t('CONFIRMATION_SUBHEADER')
                : t('RESCHEDULE_SESSION_SUBHEADER')
            }
            cancelBtnText={t('CANCEL_SESSION_CTA')}
            confirmBtnText={t('RESCHEDULE_SESSION')}
          />
        );

      case CancellationModalContent.CANCELLATION_REASONS:
        return (
          <CancellationReasons
            sessionData={sessionData}
            handleContinueClick={onContinue}
            onOtherReason={handleOtherReasonChange}
            header={
              isReschedule
                ? t('RESCHEDULE_SESSION')
                : t('CANCEL_SESSION_HEADER')
            }
            subHeader={
              isReschedule
                ? t('RESCHEDULE_DESC')
                : t('CANCEL_SESSION_SUBHEADER')
            }
          />
        );

      case CancellationModalContent.RESCHEDULE_CONTENT:
        return (
          <RescheduleSession
            sessionData={sessionData}
            cancellationReasonCode={reasonCodeRef?.current}
            onConfirm={(body) => {
              rescheduleMeetingData.current = body;
              setCurrentModalName(CancellationModalContent.LEAVE_NOTE_CONTENT);
              track(EventNames.providerBookSession, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.providerBookSession,
                eventLabel: 'provider_book_session',
                eventValue: {
                  meetingId: sessionData?.meetingId,
                  scheduledStartTime:
                    rescheduleMeetingData.current.scheduledStartTime,
                  duration: rescheduleMeetingData.current.duration,
                },
                featureVersion: 'v1',
                eventSource: 'reschedule',
              });
            }}
            headerText={t('RESCHEDULE_SESSION')}
          />
        );
      case CancellationModalContent.LEAVE_NOTE_CONTENT:
        return (
          <LeaveNote
            onSubmit={(note) => {
              if (isReschedule && rescheduleMeetingData.current) {
                rescheduleSession({
                  body: { ...rescheduleMeetingData.current, message: note },
                  meetingId: sessionData.meetingId,
                });
              }
              const isNotify =
                reasonCodeRef.current ===
                ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CLIENT_ASKED;
              onSubmit(
                note,
                isNotify,
                reasonCodeRef.current,
                otherReasonRef.current,
              );
            }}
            header={t('LEAVE_NOTE_HEADER')}
            subHeader={getLeaveNoteSubHeader()}
          />
        );
      case CancellationModalContent.LATE_CANCELATION_METRIC:
        return (
          <LateCancellationPercentage
            onCancelSession={handleCancelAnyway}
            onRescheduleSession={handleReschedule}
            header={t('CONFIRMATION_HEADER')}
            subHeader={getLateCancellationSubHeader()}
          >
            <div ref={chartComponentParentRef}>
              {isChartDataLoading ? (
                <Loader
                  useFullPage={false}
                  dimension={parentDimensions}
                  withBackdrop
                />
              ) : (
                <React.Fragment>
                  <ChartWidget
                    type="GaugeChart"
                    gaugeChartProps={{
                      totalValue: 100,
                      currentValue: getCurrentValueOfLP(),
                      arcBorderRadius: 10,
                      topColor: getLatePercentageChartColor(),
                      currentMarkerColor: getLatePercentageChartColor(),
                      markerRadius: 1,
                      markerColor: 'grey',
                      markerPrecision: 10,
                      getDisplayText,
                    }}
                  />
                  <Typography size={14} withColor="#636C73">
                    {t('CANCELLATION_NOTE', {
                      late_cancellation_count:
                        lateCancellationPercentage?.lateCancelledCount || 0,
                      no_show_count:
                        lateCancellationPercentage?.noShowCount || 0,
                    })}
                  </Typography>
                </React.Fragment>
              )}
            </div>
          </LateCancellationPercentage>
        );
      case CancellationModalContent.PENDING_INFO_CONTENT:
        return (
          <CancellationWarningModal
            onCancel={onClose}
            onConfirm={handlePendingStatus}
            header={t('PENDING_SESSION_CANCELLATION')}
            subHeader={''}
            cancelBtnText={t('CANCEL_CTA')}
            confirmBtnText={t('CONFIRM_CTA')}
          />
        );
      case CancellationModalContent.IN_PERSON_TYPE:
        return (
          <InformationModal
            headerText=""
            bodyText={t(
              sessionData.providerRole === ProviderRole.ONSITE
                ? 'ONSITE_EDIT_SESSION_BODY'
                : 'CANCEL_INPERSON',
            )}
            onClose={onClose}
            show={true}
            leftBtnLabel=""
            rightBtnLabel={t(
              sessionData.providerRole === ProviderRole.ONSITE
                ? 'MARK_NO_SHOW_NO_PROMPT_CTA'
                : 'CANCEL_INPERSON_CTA',
            )}
            contentFontSize={16}
            modalVariant="single-cta"
            borderRadius="100px"
            rightBtnClick={onClose}
          />
        );
      default:
        return 'not implemented';
    }
  };

  return (
    <Popup
      withBackdrop
      useDefaultPadding={false}
      backdropMode="glass"
      onClose={onClose}
    >
      {currentModalContent(currentModalName)}
    </Popup>
  );
}

export default CancellationReasonsModal;
