import * as Sentry from '@sentry/react';
import { QueryClient } from 'react-query';

const fetchClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError(error) {
        Sentry.captureException(error);
      },
    },
    queries: {
      onError(error) {
        Sentry.captureException(error);
      },
    },
  },
});

export default fetchClient;
