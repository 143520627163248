/* eslint-disable */
import * as React from 'react';
import './Login.scss';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import TextBox from '../../components/TextBox';
import Typography from '../../components/Typography';
import Loader from '../../components/Loader';
import { authenticateUser as _authenticateUser } from '../../../shared/redux/actions/user';
import useAuth from '../../../utilities/hooks/useAuth';
import IntroductionScreen from '../Introduction';
import eyeSlash from '../../../assets/eye-off.svg';
import eyeOpen from '../../../assets/eye-open.svg';
import useTracking from '../../../utilities/hooks/useTracking';
import { EventActions, EventCategories, EventNames } from '../../../shared/constant/Analytics';
import intellectLogo from '../../../assets/IntellectLogo.svg';
import { addItemToLocalStorage, getItemFromLocalStorage } from '../../../utilities/common/Storage';
interface ILoginForm {
  email: string;
  password: string;
}

interface ILoginScreenProps {
  authenticateUser: (
    email: string,
    password: string,
  ) => Promise<string | boolean>;
}

function LoginScreen({ authenticateUser }: ILoginScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { track } = useTracking();
  const { isLoggedIn } = useAuth();
  const userEmail = getItemFromLocalStorage('loginEmail', 'string').toString();
  const [formValues, setFormValues] = React.useState<ILoginForm>({
    email: userEmail ||'',
    password: '',
  });
  const [loginErrorMessage, setLoginErrorMessage] = React.useState<string>('');
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate('/sessions');
    }
  }, []);

  const onSignInCLick = () => {
    const { email, password } = formValues;
    if (email && password) {
      setShowLoader(true);
      authenticateUser(email, password).then((status) => {
        setShowLoader(false);
        track(EventNames.providerLogin, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.providerLogin,
          eventLabel: status === true ? 'login_succesful' : 'login_failed',
          featureVersion: 'v1',
          eventValue: email,
        });
        if (status === true) {
          navigate('/');
          addItemToLocalStorage('loginEmail', email);
        } else {
          setLoginErrorMessage(status as string);
        }
      });
    }
  };

  // TODO convert into form to make it implicit
  const onFormKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      onSignInCLick();
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      {showLoader && <Loader useFullPage />}
      <div className="action-container">
        <div className="logo-container">
          <img src={intellectLogo} className="logo" alt="intellect logo" />
        </div>
        <div className="login-form">
          <div className="login-header">
            <Typography color="primary" weight="600" size={22}>
              {t('letsSignIn')}
            </Typography>
          </div>
          <div className="login-header">
            <Typography color="primary" weight="400" size={11}>
              {t('welcomeBack')}
            </Typography>
          </div>
          <div className="form-container" onKeyDown={onFormKeyDown} role="form">
            <TextBox
              value={formValues.email}
              placeholder={t('emailAddress')}
              variant="box"
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
            />
            <div className='input-password-wrapper'>
              <TextBox
                type={showPassword ? "text" : "password"}
                value={formValues.password}
                placeholder={t('password')}
                variant="box"
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
              />
              <img
                src={showPassword ? eyeOpen : eyeSlash}
                alt="show-password -icon"
                onClick={toggleShowPassword}
              />
            </div>
            {loginErrorMessage && (
              <Typography size={12} weight="400" color="error">
                {loginErrorMessage}
              </Typography>
            )}
            <div className="btn-container">
              <Button
                label={t('FORGOT_PASSWORD')}
                variant="hyperlink"
                onClick={() => navigate('/reset-password')}
                minWidth="110px"
                width="132px"
              />
              <Button
                label={t('signIn')}
                variant="primary"
                onClick={onSignInCLick}
              />
            </div>
          </div>
        </div>
      </div>
      <IntroductionScreen />
    </div>
  );
}

const mapDispatchToProps = (dispatch: Function) => ({
  authenticateUser: (email: string, password: string) =>
    dispatch(_authenticateUser(email, password)),
});

export default connect(null, mapDispatchToProps)(LoginScreen);
