import moment from 'moment';

function isTokenExpired(expToken: number) {

  const current = moment(Date.now());
  const exp = moment(expToken);
  if (exp.diff(current, 'minutes') < 4) {
    return true;
  }

  return false;
}

export default isTokenExpired;
